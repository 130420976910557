<template>
  <!-- 学生作业-->
  <div class="container">
    <div class="main">
      <div class="header_box flexSb">
        <div class="flexSt list_box">
          <div class="flexSt list list2">
            <div class="title">年级/班级</div>
            <el-cascader
              placeholder="年级/班级"
              :options="options"
              v-model="form.grade"
              :props="{ children: 'class', multiple: true }"
              filterable
              @change="handleChange"
              clearable></el-cascader>
          </div>
          <div class="flexSt list">
            <div class="title">学生姓名</div>
            <el-input
              v-model="form.student_name"
              placeholder="请输入学生姓名"></el-input>
          </div>

          <div class="flexSt list">
            <div class="title">性别</div>
            <el-select
              @change="handleSearch"
              v-model="form.gender"
              placeholder="请选择性别">
              <el-option label="全部" :value="-1"></el-option>
              <el-option label="男" :value="1"></el-option>
              <el-option label="女" :value="0"></el-option>
            </el-select>
          </div>
          <!-- <div class="flexSt list">
          <div class="title">教师</div>
          <el-select
            clearable
            @change="handleSearch"
            v-model="form.teacher_id"
            placeholder="请选择教师"
            ><el-option
              v-for="(item, index) in teacherlist"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div> -->
          <div class="flexSt list">
            <div class="title">作业类型</div>
            <el-select
              @change="handleChangeSports"
              v-model="form.lesson_type"
              placeholder="请选择作业类型">
              <el-option
                v-for="(item, index) in homeworklist"
                :key="index"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>
          <div v-if="isShow" class="flexSt list">
            <div class="title">运动类型</div>
            <el-select
              @change="handleSearch"
              v-model="form.sports_type"
              placeholder="请选择运动类型">
              <el-option
                v-for="(item, index) in sportlist"
                :key="index"
                :label="item.label"
                :value="item.value"></el-option>
            </el-select>
          </div>

          <div class="flexSb list">
            <el-button
              @click="handleSearch"
              type="primary"
              icon="el-icon-search"
              >搜索</el-button
            >
            <el-button @click="handleclearSearch" icon="el-icon-refresh"
              >重置</el-button
            >
          </div>
        </div>
      </div>
      <!-- <div class="add flexSb">
      <div class="flexSt">
        <el-button type="primary" icon="el-icon-plus">添加作业记录</el-button>
        <el-button icon="el-icon-delete" @click="handleDelShow">删除</el-button>
      </div>
      <div class="flexSb">
        <el-button icon="el-icon-upload2">导出</el-button>
      </div>
    </div> -->
      <div class="table_box">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableHeaderColor"
          :header-cell-class-name="cellClass"
          :cell-class-name="returnName">
          <el-table-column type="selection" width="25"> </el-table-column>
          <el-table-column
            :index="indexMethod"
            type="index"
            label="序号"
            width="60"
            align="center">
          </el-table-column>

          <el-table-column label="学生" width="170" align="center">
            <template slot-scope="scope">
              <div class="flexSc">
                <a href="">
                  <img
                    v-if="scope.row.user_pic"
                    class="img"
                    :src="scope.row.user_pic"
                    alt="" />
                  <img
                    v-else
                    class="img"
                    src="../../../assets/img/1633.png"
                    alt="" />
                </a>
                <div>
                  <div class="flexSt">
                    <a href="">
                      <div class="name">{{ scope.row.student_name }}</div>
                    </a>
                    <img
                      v-if="scope.row.gender == 1"
                      class="img_gender"
                      src="../../../assets/img/206.png"
                      alt="" />
                    <img
                      v-else
                      class="img_gender"
                      src="../../../assets/img/207.png"
                      alt="" />
                  </div>
                  <div>班号：{{ scope.row.number }}</div>
                </div>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="grade_name"
            label="年级"
            sortable
            min-width="100"
            align="center">
            <template slot-scope="scope">
              <div style="padding-right: 16px">
                {{ scope.row.grade_name ? scope.row.grade_name : "- -" }}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="所在班级" align="center" min-width="110">
            <template slot-scope="scope">
              <div>
                {{ scope.row.class_name ? scope.row.class_name : "- -" }}
              </div>
            </template></el-table-column
          >

          <el-table-column label="作业名称" align="center" min-width="110">
            <template slot-scope="scope">
              <div>
                {{ scope.row.homework_name ? scope.row.homework_name : "- -" }}
              </div>
            </template></el-table-column
          >
          <el-table-column label="作业内容" align="center" min-width="110">
            <template slot-scope="scope">
              <div>
                {{ scope.row.homework ? scope.row.homework : "- -" }}
              </div>
            </template></el-table-column
          >
          <el-table-column width="80" label="状态" align="center">
            <template slot-scope="scope">
              <div>
                <el-tag v-if="scope.row.status == 1">进行中</el-tag>

                <el-tag type="success" v-if="scope.row.status == 2"
                  >已完成</el-tag
                >
                <el-tag type="danger" v-if="scope.row.status == 3"
                  >已结束</el-tag
                >
                <el-tag type="info" v-if="scope.row.status == 0">未开始</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="完成率"
            prop="progress"
            sortable
            align="center"
            min-width="100"
            fixed="right">
            <template slot-scope="scope">
              <div>
                {{ scope.row.progress ? scope.row.progress : "- -" }}
              </div>
            </template></el-table-column
          >
          <!-- <el-table-column width="120" fixed="right" label="操作" align="center">
          <template slot-scope="scope">
            <div class="flexSc">
              <el-button type="text">查看</el-button>
              <el-button type="text">编辑</el-button>
            </div>
          </template>
        </el-table-column> -->
        </el-table>

        <div class="flexSe elpla">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageInfo.page"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="pageInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.total">
          </el-pagination>
        </div>
      </div>
      <delDialog ref="delDialog"></delDialog>
    </div>
  </div>
</template>
<script>
import delDialog from "../../components/delDialog";
import { gethomework } from "@/api/classroon/classroom";
import { getSchoolID } from "@/utils/auth";
import { gradeClassList, getClassTeacherList } from "@/api/evaluation";
export default {
  components: { delDialog },
  data() {
    return {
      school_id: "",
      isShow: true,
      gradeID: "",
      teacherlist: [],
      schoolist: [
        {
          name: "全区",
          id: -1,
        },
      ],
      homeworklist: [
        {
          label: "全部",
          value: -1,
        },
        {
          label: "体育运动",
          value: 1,
        },
        {
          label: "知识素养",
          value: 2,
        },
      ],
      options: [],
      genderlist: [],
      value2: [],
      form: {
        teacher_id: "",
        lesson_type: -1,
        school_id: -1,
        grade: "",
        student_name: "",
        gender: -1,
      },
      sportlist: [],
      start_time: "",
      end_time: "",
      value1: [],
      pageInfo: {
        pageSize: 10,
        page: 1,
        total: 0,
      },
      tableData: [],
    };
  },
  computed: {
    indexMethod() {
      // 当前页数 - 1 * 每页数据条数 + 1
      return (this.pageInfo.page - 1) * this.pageInfo.pageSize + 1;
    },
  },
  mounted() {
    this.school_id = getSchoolID();
    let val = this.$route.query;
    this.start_time = val.start_time;
    this.end_time = val.end_time;
    this.handleGetGradeClassList();
    // this.getTeacher();
    this.handlegethomework();
  },
  methods: {
    handleChange(val) {
      if (val.length > 0) {
        if (val.length == 1) {
          this.form.class_id = val[0][1];
          this.gradeID = val[0][0];
        } else {
          let class_id = [];
          let gradeID = [];
          val.forEach((item, index) => {
            class_id.push(item[1]);
            gradeID.push(item[0]);
          });
          this.form.class_id = class_id.join(",");
          this.gradeID = gradeID.join(",");
        }
      } else {
        this.form.class_id = "";
        this.gradeID = "";
      }

      this.pageInfo.page = 1;
      this.form.teacher_id = "";
      // this.getTeacher();
      this.handlegethomework();
    },
    handleChangeSports() {
      console.log(this.form.lesson_type);
      if (this.form.lesson_type != 2) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
      this.handleSearch();
    },
    async handlegethomework() {
      let data = {
        ...this.form,
        start_time: this.start_time,
        end_time: this.end_time,
        page: this.pageInfo.page,
        per_page: this.pageInfo.pageSize,
      };
      data.grade = this.gradeID;
      await gethomework(data).then((res) => {
        this.pageInfo.total = res.data.total;
        this.tableData = res.data.data;
      });
    },
    // 获取教师列表
    // async getTeacher() {
    //   // const res = await this.axios.get(
    //   //   `/school/teacher?school_id=${this.form.school_id}&per_page=10000&page=1`
    //   // );
    //   // if (res.code === 1) {
    //   //   this.teacherlist = res.data.data;
    //   // }

    //   let data = {
    //     class_id: this.form.class_id,
    //     school_id: this.school_id,
    //   };
    //   await getClassTeacherList(data).then((res) => {
    //     this.teacherlist = res.data;
    //   });
    // },
    // 年级班级
    async handleGetGradeClassList() {
      let data = {
        school_id: this.school_id,
      };
      await gradeClassList(data).then((res) => {
        this.options = res.data;
      });
    },
    handleChangeSchool() {
      this.form.class_id = "";
      this.form.grade = "";
      this.form.teacher_id = "";
      this.gradeID = "";
      this.handleGetGradeClassList();
      // this.getTeacher();
      this.handleSearch();
    },
    handleDelShow() {
      this.$refs.delDialog.handleShow();
    },
    returnName(obj) {
      if (obj.columnIndex == 0) {
        return "firstCell";
      }
    },
    handleSearch() {
      this.pageInfo.page = 1;
      this.handlegethomework();
    },
    handleclearSearch() {
      this.form = {
        teacher_id: "",
        lesson_type: -1,
        school_id: -1,
        grade: "",
        student_name: "",
        gender: -1,
      };
      this.handleSearch();
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    cellClass() {
      return "disableSelection";
    },
    tableHeaderColor({ row, column, rowIndex, columnIndex }) {
      return {
        fontSize: "16px",
        color: "#333333",
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "#F7F7F7",
      };
    },
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.pageInfo.page = 1;
      this.handlegethomework();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageInfo.page = val;
      this.handlegethomework();
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: stretch;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100%;
}
.main {
  flex: 1;
  background-color: #fff;
  border-radius: 4px;
  padding: 35px 25px;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
}
.container {
  /deep/.firstCell {
    .cell {
      overflow: unset !important;
    }
  }
  .table_box {
    ::-webkit-scrollbar {
      width: 10px !important;
      height: 10px !important;
      // background-color: #ccc !important;
    }
    ::-webkit-scrollbar-track {
      // background-color: #bbbbbb;
    }
    /* 滚动条上的滚动滑块 */
    ::-webkit-scrollbar-thumb {
      background-color: #ccc;
    }
    /deep/.tableImg {
      /deep/.cell {
        height: 60px;
      }
    }
    .table_img {
      border-radius: 10px;
      width: 80px;
    }
    .img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 14px;
    }
    .img_gender {
      width: 20px;
      height: 20px;
    }
    .name {
      font-size: 14px;
      color: #1890ff;
      margin-right: 6px;
    }
  }
  .list_box {
    flex-wrap: wrap;
  }
  .elpla {
    margin-top: 30px;
  }
  .header_box {
    border-bottom: 1px solid rgba(242, 242, 242, 1);
    padding-bottom: 20px;
    box-sizing: border-box;
    flex-wrap: wrap;
    .list {
      margin-bottom: 10px;
    }
    /deep/.title {
      color: rgb(78, 89, 105);
      font-size: 14px;
      margin-right: 8px;
      // min-width: 60px;
    }
    .list2 {
      /deep/.el-cascader {
        width: 147px !important;
        margin-right: 10px;
      }
    }
    .sex {
      /deep/.el-form-item__label {
        width: 47px !important;
      }
      /deep/.el-form-item__content {
        margin-left: 60px !important;
      }
      /deep/.el-select {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        width: 127px !important;
      }
    }
    .sex2 {
      /deep/.el-form-item__label {
        width: 80px !important;
      }
      /deep/.el-form-item__content {
        width: 167px !important;
      }
      /deep/.el-select {
        width: 147px !important;
        height: 32px !important;
      }
    }
    .sex3 {
      /deep/.el-select,
      /deep/.el-input__inner,
      /deep/.el-input {
        width: 127px !important;
        height: 32px !important;
      }
      /deep/.el-form-item__content {
        // margin-left: 40px !important;
      }
    }
    /deep/.el-button {
      padding: 0 20px;
      // height: 30px !important;
      // line-height: 30px;
      height: 40px !important;
      line-height: 40px;
    }

    /deep/.el-form-item__content,
    /deep/.el-form-item__labe {
      height: 32px !important;
      width: 147px;
    }
    // /deep/.el-input__inner,   /deep/.el-input,
    /deep/.el-input {
      margin-right: 15px;
    }
    /deep/.el-input__inner,
    /deep/.el-input {
      width: 147px;
    }

    /deep/.el-input__icon {
      line-height: 32px !important;
    }

    /deep/.el-form-item__content {
      width: 171px !important;
    }
    /deep/.el-date-editor {
      width: 357px;
    }
    /deep/ .time {
      margin-right: 10px;
      .el-input__icon {
        line-height: 25px !important;
      }
      .el-date-editor {
        .el-range-separator {
          line-height: 25px !important;
        }
      }
    }
  }

  .add {
    margin: 17px 0;
    /deep/.el-button {
      padding: 0 20px;
      height: 30px !important;
      line-height: 30px;
    }
  }
}
</style>
