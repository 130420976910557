<template>
  <div>
    <el-dialog
      title="操作提示"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="cont"><i class="el-icon-info"></i><span>确认删除?</span></div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleDel">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    handleDel() {
      this.$emit("handleDel");
      this.dialogVisible = false;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleShow() {
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.cont {
  font-size: 18px;
}
</style>
